import { PlaceModule } from '../modules/module';
import { PlaceResource } from '../resources/resource';
import { EncryptionLevel } from '../settings/interfaces';
import { PlaceSettings } from '../settings/settings';
export class PlaceSystem extends PlaceResource {
  constructor(raw_data = {}) {
    var _a;
    super(raw_data);
    /** Tuple of user settings of differring encryption levels for the system */
    this.settings = [null, null, null, null];
    /**
     * List of modules associated with the system.
     * Only available from the show method with the `complete` query parameter
     */
    this.module_list = [];
    this.display_name = raw_data.display_name || '';
    this.description = raw_data.description || '';
    this.email = raw_data.email || '';
    this.code = raw_data.code || '';
    this.capacity = raw_data.capacity || 0;
    this.features = raw_data.features || [];
    this.bookable = raw_data.bookable || false;
    this.public = (_a = raw_data.public) !== null && _a !== void 0 ? _a : false;
    this.installed_ui_devices = raw_data.installed_ui_devices || 0;
    this.support_url = raw_data.support_url || '';
    this.map_id = raw_data.map_id || '';
    this.modules = raw_data.modules || [];
    this.images = raw_data.images || [];
    this.zones = raw_data.zones || [];
    this.settings = raw_data.settings || [null, null, null, null];
    this.timezone = raw_data.timezone || '';
    this.signage = raw_data.signage || false;
    this.playlists = raw_data.playlists || [];
    this.orientation = raw_data.orientation || 'unspecified';
    if (typeof this.settings !== 'object') {
      this.settings = [null, null, null, null];
    }
    for (const level in EncryptionLevel) {
      if (!isNaN(Number(level)) && !this.settings[level]) {
        this.settings[level] = new PlaceSettings({
          parent_id: this.id,
          encryption_level: +level
        });
      }
    }
    if (raw_data.module_data && raw_data.module_data instanceof Array) {
      this.module_list = raw_data.module_data.map(mod => new PlaceModule(mod));
    }
  }
}
