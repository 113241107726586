import { PlaceResource } from '../resources/resource';
export class PlaceSAMLSource extends PlaceResource {
  constructor(raw_data = {}) {
    super(raw_data);
    /** Type of auth source */
    this.type = 'saml';
    this.authority_id = raw_data.authority_id || '';
    this.issuer = raw_data.issuer || '';
    this.idp_sso_target_url_runtime_params = raw_data.idp_sso_target_url_runtime_params || {};
    this.name_identifier_format = raw_data.name_identifier_format || '';
    this.uid_attribute = raw_data.uid_attribute || '';
    this.assertion_consumer_service_url = raw_data.assertion_consumer_service_url || '';
    this.idp_sso_target_url = raw_data.idp_sso_target_url || '';
    this.idp_cert = raw_data.idp_cert || '';
    this.idp_cert_fingerprint = raw_data.idp_cert_fingerprint || '';
    this.attribute_service_name = raw_data.attribute_service_name || '';
    this.attribute_statements = raw_data.attribute_statements || {};
    this.request_attributes = raw_data.request_attributes || [];
    this.idp_slo_target_url = raw_data.idp_slo_target_url || '';
    this.slo_default_relay_state = raw_data.slo_default_relay_state || '';
  }
}
