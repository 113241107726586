export const MOCK_AUTHORITY = {
  id: 'mock-authority',
  name: 'localhost:4200',
  description: '',
  domain: 'localhost:4200',
  login_url: `/login?continue={{url}}`,
  logout_url: `/logout`,
  session: true,
  production: false,
  config: {},
  version: `2.0.0`
};
