import { MockPlaceWebsocketSystem } from './mock-system';
/**
 * @private
 * List of registered mock systems for websocket bindings
 */
const _mock_systems = {};
/** Register a mock system for websocket bindings */
export function registerSystem(id, details) {
  _mock_systems[id] = new MockPlaceWebsocketSystem(details);
  return _mock_systems[id];
}
/** Retrieve a mock system for websocket bindings */
export function mockSystem(id) {
  return _mock_systems[id];
}
/** Remove a mock system for websocket bindings */
export function deregisterSystem(id) {
  delete _mock_systems[id];
}
