import { PlaceResource } from '../resources/resource';
export class PlaceTrigger extends PlaceResource {
  /** ID of the system associated with the trigger */
  get system_id() {
    return this.control_system_id;
  }
  /** Actions to perform when the trigger is activated */
  get actions() {
    const actions = this._actions;
    const fn_list = (actions.functions || []).map(i => Object.assign(Object.assign({}, i), {
      args: Object.assign({}, i.args)
    }));
    const mail_list = (actions.mailers || []).map(i => Object.assign(Object.assign({}, i), {
      emails: [...i.emails]
    }));
    return {
      functions: fn_list,
      mailers: mail_list
    };
  }
  /** Conditions for activating the trigger */
  get conditions() {
    const conditions = this._conditions;
    const cmp_list = (conditions.comparisons || []).map(i => Object.assign(Object.assign({}, i), {
      left: typeof i.left === 'object' ? Object.assign({}, i.left) : i.left,
      right: typeof i.right === 'object' ? Object.assign({}, i.right) : i.right
    }));
    const time_list = (conditions.time_dependents || []).map(i => Object.assign({}, i));
    return {
      comparisons: cmp_list,
      time_dependents: time_list
    };
  }
  constructor(raw_data = {}) {
    super(raw_data);
    this.description = raw_data.description || '';
    this._actions = raw_data.actions || {
      functions: [],
      mailers: []
    };
    this._conditions = raw_data.conditions || {
      comparisons: [],
      time_dependents: []
    };
    this.debounce_period = raw_data.debounce_period || 0;
    this.important = raw_data.important || false;
    this.enabled = raw_data.enabled || false;
    this.webhook_secret = raw_data.webhook_secret || '';
    this.control_system_id = raw_data.system_id || raw_data.control_system_id || '';
    this.zone_id = raw_data.zone_id || '';
    this.system_name = raw_data.system_name || (raw_data.control_system ? raw_data.control_system.name : '');
    this.enable_webhook = raw_data.enable_webhook || false;
    this.exec_enabled = raw_data.exec_enabled || false;
    this.supported_methods = raw_data.supported_methods || ['POST'];
    this.activated_count = raw_data.activated_count || raw_data.trigger_count || 0;
    this.playlists = raw_data.playlists || [];
    this.trigger_id = raw_data.trigger_id || '';
  }
}
