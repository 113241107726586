import { create, query, remove, show, update } from '../resources/functions';
import { PlaceApplication } from './application';
/**
 * @private
 */
const PATH = 'oauth_apps';
/** Convert raw server data to an application object */
function process(item) {
  return new PlaceApplication(item);
}
/**
 * Query the available applications
 * @param query_params Query parameters to add the to request URL
 */
export function queryApplications(query_params = {}) {
  return query({
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Get the data for an application
 * @param id ID of the application to retrieve
 * @param query_params Query parameters to add the to request URL
 */
export function showApplication(id, query_params = {}) {
  return show({
    id,
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Update the application in the database
 * @param id ID of the application
 * @param form_data New values for the application
 * @param query_params Query parameters to add the to request URL
 * @param method HTTP verb to use on request. Defaults to `patch`
 */
export function updateApplication(id, form_data, method = 'patch') {
  return update({
    id,
    form_data,
    query_params: {},
    method,
    fn: process,
    path: PATH
  });
}
/**
 * Add a new application to the database
 * @param form_data Application data
 * @param query_params Query parameters to add the to request URL
 */
export function addApplication(form_data) {
  return create({
    form_data,
    query_params: {},
    fn: process,
    path: PATH
  });
}
/**
 * Remove an application from the database
 * @param id ID of the application
 * @param query_params Query parameters to add the to request URL
 */
export function removeApplication(id, query_params = {}) {
  return remove({
    id,
    query_params,
    path: PATH
  });
}
