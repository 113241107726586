import { humanReadableByteCount } from '../utilities/general';
export class PlaceCluster {
  constructor(raw_data = {}) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    this.id = raw_data.id || raw_data.core_id || '';
    this.compiled_drivers = raw_data.compiled_drivers || [];
    this.available_repositories = raw_data.available_repositories || ((_a = raw_data.status) === null || _a === void 0 ? void 0 : _a.available_repositories) || [];
    this.running_drivers = raw_data.running_drivers || ((_b = raw_data.status) === null || _b === void 0 ? void 0 : _b.running_drivers) || 0;
    this.module_instances = raw_data.module_instances || ((_c = raw_data.status) === null || _c === void 0 ? void 0 : _c.module_instances) || 0;
    this.unavailable_repositories = raw_data.unavailable_repositories || ((_d = raw_data.status) === null || _d === void 0 ? void 0 : _d.unavailable_repositories) || [];
    this.unavailable_drivers = raw_data.unavailable_drivers || ((_e = raw_data.status) === null || _e === void 0 ? void 0 : _e.unavailable_drivers) || [];
    this.hostname = raw_data.hostname || ((_f = raw_data.load) === null || _f === void 0 ? void 0 : _f.local.hostname) || '';
    this.cpu_count = raw_data.cpu_count || ((_g = raw_data.load) === null || _g === void 0 ? void 0 : _g.local.cpu_count) || 0;
    this.core_cpu = raw_data.core_cpu || ((_h = raw_data.load) === null || _h === void 0 ? void 0 : _h.local.core_cpu) || 0;
    this.total_cpu = raw_data.total_cpu || ((_j = raw_data.load) === null || _j === void 0 ? void 0 : _j.local.total_cpu) || 0;
    this.memory_total = raw_data.memory_total || ((_k = raw_data.load) === null || _k === void 0 ? void 0 : _k.local.memory_total) || 0;
    this.memory_usage = raw_data.memory_usage || ((_l = raw_data.load) === null || _l === void 0 ? void 0 : _l.local.memory_usage) || 0;
    this.core_memory = raw_data.core_memory || ((_m = raw_data.load) === null || _m === void 0 ? void 0 : _m.local.core_memory) || 0;
    this.run_counts = raw_data.run_counts || ((_p = (_o = raw_data.status) === null || _o === void 0 ? void 0 : _o.run_counts) === null || _p === void 0 ? void 0 : _p.local) || {
      modules: 0,
      drivers: 0
    };
    this.memory_percentage = +(this.memory_usage / this.memory_total * 100).toFixed(4);
    this.used_memory = humanReadableByteCount(this.memory_usage * 1024);
    this.total_memory = humanReadableByteCount(this.memory_total * 1024);
    const edges = ((_q = raw_data.load) === null || _q === void 0 ? void 0 : _q.edge) || {};
    this.edge_nodes = raw_data.edge_nodes || Object.keys(edges).map(_ => {
      var _a, _b;
      return Object.assign(Object.assign({
        id: _
      }, edges[_]), {
        run_count: ((_b = (_a = raw_data.status) === null || _a === void 0 ? void 0 : _a.run_count) === null || _b === void 0 ? void 0 : _b.edge[_]) || {}
      });
    }) || [];
  }
}
