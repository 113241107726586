import { PlaceCluster } from './cluster';
import { PlaceProcess } from './process';
import { query, remove, show } from '../resources/functions';
/**
 * @private
 */
const PATH = 'cluster';
/** Convert raw server data to a cluster object */
function process(item) {
  return new PlaceCluster(item);
}
/**
 * Query the available clusters
 * @param query_params Query parameters to add the to request URL
 */
export function queryClusters(query_params = {}) {
  return query({
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Query the available process for a cluster
 * @param id ID of the cluster to query
 * @param query_params Query parameters to add the to request URL
 */
export function queryProcesses(id, query_params = {}) {
  return show({
    id,
    query_params,
    fn: list => list.map(item => new PlaceProcess(id, item)),
    path: PATH
  });
}
/**
 * Terminal a process in a cluster
 * @param id ID of the cluster associated with the process
 * @param driver Name of the process to kill
 */
export function terminateProcess(id, driver) {
  return remove({
    id,
    query_params: {
      driver
    },
    path: PATH
  });
}
