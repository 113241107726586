import { execute, REQUEST_TIMEOUT } from './functions';
import { PlaceVariableBinding } from './status-variable';
export class PlaceModuleBinding {
  constructor(_system, _id) {
    this._system = _system;
    this._id = _id;
    /** Mapping of module bindings */
    this._bindings = {};
  }
  get id() {
    return `${this.name}_${this.index}`;
  }
  /** Parent system of the module */
  get system() {
    return this._system;
  }
  /** Module index */
  get index() {
    const parts = this._id.split('_');
    const index = parts.pop();
    return parseInt(index || '', 10) || 1;
  }
  /** Module name */
  get name() {
    const parts = this._id.split('_');
    parts.pop();
    return parts.join('_');
  }
  /**
   * Get binding with the given name
   * @param name Name of the binding
   */
  binding(name) {
    if (!this._bindings[name]) {
      this._bindings[name] = new PlaceVariableBinding(this, name);
    }
    return this._bindings[name];
  }
  /**
   * Execute method on the engine module
   * @param method Name of the method
   * @param args Array of arguments to pass to the method
   */
  execute(method, args, timeout_delay = REQUEST_TIMEOUT) {
    return execute({
      sys: this._system.id,
      mod: this.name,
      index: this.index,
      name: method,
      args
    }, timeout_delay);
  }
}
