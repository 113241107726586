import { PlaceResource } from '../resources/resource';
import { EncryptionLevel } from './interfaces';
export class PlaceSettings extends PlaceResource {
  /** Contents of the settings */
  get value() {
    return this.settings_string;
  }
  constructor(raw_data = {}) {
    super(raw_data);
    this.parent_id = raw_data.parent_id || '';
    this.updated_at = raw_data.updated_at || Math.floor(new Date().getTime() / 1000);
    this.settings_string = raw_data.settings_string || '';
    this.encryption_level = raw_data.encryption_level || EncryptionLevel.None;
    this.keys = raw_data.keys || [];
    this.modified_by_id = raw_data.modified_by_id || '';
  }
}
