import { create, query, remove, show, task, update } from '../resources/functions';
import { PlaceTrigger } from './trigger';
/**
 * @private
 */
const PATH = 'triggers';
/** Convert raw server data to a trigger object */
function process(item) {
  return new PlaceTrigger(item);
}
/**
 * Query the available triggers
 * @param query_params Query parameters to add the to request URL
 */
export function queryTriggers(query_params = {}) {
  return query({
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Get the data for a trigger
 * @param id ID of the trigger to retrieve
 * @param query_params Query parameters to add the to request URL
 */
export function showTrigger(id, query_params = {}) {
  return show({
    id,
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Update the trigger in the database
 * @param id ID of the trigger
 * @param form_data New values for the trigger
 * @param query_params Query parameters to add the to request URL
 * @param method HTTP verb to use on request. Defaults to `patch`
 */
export function updateTrigger(id, form_data, method = 'patch') {
  return update({
    id,
    form_data,
    query_params: {},
    method,
    fn: process,
    path: PATH
  });
}
/**
 * Add a new trigger to the database
 * @param form_data Trigger data
 * @param query_params Query parameters to add the to request URL
 */
export function addTrigger(form_data) {
  return create({
    form_data,
    query_params: {},
    fn: process,
    path: PATH
  });
}
/**
 * Remove an trigger from the database
 * @param id ID of the trigger
 * @param query_params Query parameters to add the to request URL
 */
export function removeTrigger(id, query_params = {}) {
  return remove({
    id,
    query_params,
    path: PATH
  });
}
/**
 * List systems that contain instances of a trigger
 * @param id ID of the trigger to grab system instances for
 */
export function listTriggerInstances(id) {
  return task({
    id,
    task_name: `instances`,
    form_data: {},
    method: 'get',
    callback: data => data.map(i => new PlaceTrigger(i)),
    path: PATH
  });
}
