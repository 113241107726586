import { __awaiter } from "tslib";
import { of, throwError } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { filter, mergeMap, retryWhen, switchMap, take } from 'rxjs/operators';
import { apiKey, authority, invalidateToken, isMock, listenForToken, refreshAuthority, sendToLogin, token } from '../auth/functions';
import { log } from '../utilities/general';
import { mockRequest } from './mock';
/**
 * Method store to allow attaching spies for testing
 * @hidden
 */
export const engine_http = {
  log
};
/**
 * @private
 * Map of headers from the last request made
 */
const _response_headers = {};
export function responseHeaders(url, /* istanbul ignore next */
headers = _response_headers) {
  return headers[url] || {};
}
export function get(url, options, handler = request) {
  /* istanbul ignore else */
  if (!options) {
    options = {
      response_type: 'json'
    };
  }
  return handler('GET', url, Object.assign({
    response_type: 'json'
  }, options));
}
export function post(url, body, options, handler = request) {
  /* istanbul ignore else */
  if (!options) {
    options = {
      response_type: 'json'
    };
  }
  return handler('POST', url, Object.assign({
    body,
    response_type: 'json'
  }, options));
}
export function put(url, body, options, handler = request) {
  /* istanbul ignore else */
  if (!options) {
    options = {
      response_type: 'json'
    };
  }
  return handler('PUT', url, Object.assign({
    body,
    response_type: 'json'
  }, options));
}
export function patch(url, body, options, handler = request) {
  /* istanbul ignore else */
  if (!options) {
    options = {
      response_type: 'json'
    };
  }
  return handler('PATCH', url, Object.assign({
    body,
    response_type: 'json'
  }, options));
}
export function del(url, options, handler = request) {
  /* istanbul ignore else */
  if (!options) {
    options = {
      response_type: 'void'
    };
  }
  return handler('DELETE', url, Object.assign({
    response_type: 'void'
  }, options));
}
/**
 * @private
 * Convert response into the format requested
 * @param response Request response contents
 * @param type Type of data to return
 */
export function transform(resp_1, type_1) {
  return __awaiter(this, arguments, void 0, function* (resp, type, headers = _response_headers) {
    /* istanbul ignore else */
    if (resp.headers) {
      const map = {};
      if (resp.headers.forEach) {
        resp.headers.forEach((v, k) => map[k.toLowerCase()] = v);
      } else {
        Object.keys(resp.headers).forEach(k => map[k.toLowerCase()] = resp.headers[k]);
      }
      headers[resp.url || ''] = map;
    }
    switch (type) {
      case 'json':
        return yield resp.json().catch(() => ({}));
      case 'text':
        return yield resp.text();
    }
  });
}
/**
 * @private
 */
const reloadAuth = () => {
  invalidateToken();
  refreshAuthority().then(() => null, () => setTimeout(() => reloadAuth(), 1000));
};
/**
 * @private
 * Perform fetch request
 * @param method Request verb. `GET`, `POST`, `PUT`, `PATCH`, or `DELETE`
 * @param url URL of the request endpoint
 * @param options Options to add to the request
 */
export function request(method, url, options, is_mock = isMock, mock_handler = mockRequest, success = transform) {
  if (is_mock()) {
    const request_obs = mock_handler(method, url, options === null || options === void 0 ? void 0 : options.body);
    if (request_obs) {
      return request_obs;
    }
  }
  options.headers = options.headers || {};
  options.headers['Content-Type'] = `application/json`;
  return listenForToken().pipe(filter(_ => _), take(1), switchMap(_ => {
    if (token() === 'x-api-key') {
      options.headers['X-API-Key'] = apiKey();
    } else {
      options.headers.Authorization = `Bearer ${token()}`;
    }
    return fromFetch(url, Object.assign(Object.assign({}, options), {
      body: JSON.stringify(options.body),
      method,
      credentials: 'same-origin'
    }));
  }), switchMap(resp => {
    if (resp.ok) {
      return success(resp, options.response_type);
    }
    return throwError(resp);
  }), retryWhen(attempts => attempts.pipe(mergeMap((error, i) => {
    if (error.status === 511) {
      sendToLogin(authority());
      return of(error);
    }
    if (i + 1 > 4 || error.status !== 401) {
      return throwError(error || {});
    }
    log('HTTP', 'Auth error', error);
    reloadAuth();
    return of(error);
  }))));
}
