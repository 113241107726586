import { PlaceResource } from '../resources/resource';
export class PlaceLDAPSource extends PlaceResource {
  constructor(raw_data = {}) {
    super(raw_data);
    /** Type of auth source */
    this.type = 'ldap';
    this.authority_id = raw_data.authority_id || '';
    this.host = raw_data.host || '';
    this.port = raw_data.port || 636;
    this.auth_method = raw_data.auth_method || 'ssl';
    this.uid = raw_data.uid || '';
    this.base = raw_data.base || '';
    this.bind_dn = raw_data.bind_dn || '';
    this.password = raw_data.password || '';
    this.filter = raw_data.filter || '';
  }
}
