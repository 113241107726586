import { PlaceDriver } from '../drivers/driver';
import { PlaceDriverRole } from '../drivers/enums';
import { PlaceResource } from '../resources/resource';
import { EncryptionLevel } from '../settings/interfaces';
import { PlaceSettings } from '../settings/settings';
import { PlaceSystem } from '../systems/system';
export class PlaceModule extends PlaceResource {
  /** ID of the system associated with the module */
  get system_id() {
    return this.control_system_id;
  }
  constructor(raw_data = {}) {
    var _a;
    super(raw_data);
    /** Tuple of user settings of differring encryption levels for the module */
    this.settings = [null, null, null, null];
    this.driver_id = raw_data.driver_id || raw_data.dependency_id || '';
    this.control_system_id = raw_data.control_system_id || '';
    this.edge_id = raw_data.edge_id || '';
    this.ip = raw_data.ip || '';
    this.tls = raw_data.tls || false;
    this.udp = raw_data.udp || false;
    this.port = raw_data.port || 1;
    this.makebreak = raw_data.makebreak || false;
    this.uri = raw_data.uri || '';
    this.custom_name = raw_data.custom_name || '';
    this.role = (_a = raw_data.role) !== null && _a !== void 0 ? _a : PlaceDriverRole.Logic;
    this.notes = raw_data.notes || '';
    this.ignore_connected = raw_data.ignore_connected || false;
    this.connected = raw_data.connected;
    this.running = raw_data.running || false;
    this.updated_at = raw_data.updated_at || 0;
    this.system = new PlaceSystem(raw_data.control_system || raw_data.system);
    this.has_runtime_error = raw_data.has_runtime_error || false;
    this.error_timestamp = raw_data.error_timestamp || 0;
    this.driver = new PlaceDriver(raw_data.dependency || raw_data.driver);
    this.settings = raw_data.settings || [null, null, null, null];
    if (typeof this.settings !== 'object') {
      this.settings = [null, null, null, null];
    }
    for (const level in EncryptionLevel) {
      if (!isNaN(Number(level)) && !this.settings[level]) {
        this.settings[level] = new PlaceSettings({
          parent_id: this.id,
          encryption_level: +level
        });
      }
    }
  }
  /**
   * Convert object into plain object
   */
  toJSON(keep_system = false) {
    const obj = super.toJSON();
    if (obj.role !== PlaceDriverRole.Logic && !keep_system || !obj.control_system_id) {
      delete obj.control_system_id;
    }
    delete obj.driver;
    delete obj.system;
    delete obj.error_timestamp;
    delete obj.has_runtime_error;
    return obj;
  }
}
