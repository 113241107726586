import { create, query, remove, show, task, update } from '../resources/functions';
import { PlaceSettings } from '../settings/settings';
import { PlaceTrigger } from '../triggers/trigger';
import { PlaceZone } from '../zones/zone';
import { PlaceSystem } from './system';
/**
 * @private
 */
const PATH = 'systems';
/** Convert raw server data to an system object */
function process(item) {
  return new PlaceSystem(item);
}
/**
 * Query the available systems
 * @param query_params Query parameters to add the to request URL
 */
export function querySystems(query_params = {}) {
  return query({
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Query the available systems
 * @param query_params Query parameters to add the to request URL
 */
export function querySystemsWithEmails(query_params = {}) {
  return query({
    query_params,
    fn: process,
    path: `${PATH}/with_emails`
  });
}
/**
 * Get the data for a system
 * @param id ID of the system to retrieve
 * @param query_params Query parameters to add the to request URL
 */
export function showSystem(id, query_params = {}) {
  return show({
    id,
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Update the system in the database
 * @param id ID of the system
 * @param form_data New values for the system
 * @param query_params Query parameters to add the to request URL
 * @param method HTTP verb to use on request. Defaults to `patch`
 */
export function updateSystem(id, form_data, method = 'patch') {
  return update({
    id,
    form_data,
    query_params: {},
    method,
    fn: process,
    path: PATH
  });
}
/**
 * Add a new system to the database
 * @param form_data System data
 * @param query_params Query parameters to add the to request URL
 */
export function addSystem(form_data) {
  return create({
    form_data,
    query_params: {},
    fn: process,
    path: PATH
  });
}
/**
 * Remove an system from the database
 * @param id ID of the system
 * @param query_params Query parameters to add the to request URL
 */
export function removeSystem(id, query_params = {}) {
  return remove({
    id,
    query_params,
    path: PATH
  });
}
/**
 * Add module to the given system
 * @param id System ID
 * @param module_id ID of the module to add
 */
export function addSystemModule(id, module_id, data = {}) {
  return task({
    id,
    task_name: `module/${module_id}`,
    form_data: data,
    method: 'put',
    callback: d => process(d),
    path: PATH
  });
}
/**
 * Remove module from the given system
 * @param id System ID
 * @param module_id ID of the module to remove
 */
export function removeSystemModule(id, module_id) {
  return task({
    id,
    task_name: `module/${module_id}`,
    form_data: {},
    method: 'del',
    callback: d => process(d),
    path: PATH
  });
}
/**
 * Start the given system and clears any existing caches
 * @param id System ID
 */
export function startSystem(id) {
  return task({
    id,
    task_name: 'start',
    form_data: {},
    path: PATH
  });
}
/**
 * Stops all modules in the given system
 * @param id System ID
 */
export function stopSystem(id) {
  return task({
    id,
    task_name: 'stop',
    path: PATH
  });
}
/**
 * Execute a function of the given system module
 * @param id System ID
 * @param method Name of the function to execute
 * @param module Class name of the Module e.g. `Display`, `Lighting` etc.
 * @param index Module index. Defaults to `1`
 * @param args Array of arguments to pass to the executed method
 */
export function executeOnSystem(id, method, module, index = 1, args = []) {
  return task({
    id,
    task_name: `${module}_${index}/${encodeURIComponent(method)}`,
    form_data: args,
    path: PATH
  });
}
/**
 * Get the state of the given system module
 * @param id System ID
 * @param module Class name of the Module e.g. `Display`, `Lighting` etc.
 * @param index Module index. Defaults to `1`
 * @param lookup Status variable of interest. If set it will return only the state of this variable
 */
export function systemModuleState(id, module, index = 1) {
  return task({
    id,
    task_name: `${module}_${index}`,
    method: 'get',
    path: PATH
  });
}
/**
 * Get the state of the given system module
 * @param id System ID
 * @param module Class name of the Module e.g. `Display`, `Lighting` etc.
 * @param index Module index. Defaults to `1`
 * @param lookup Status variable of interest. If set it will return only the state of this variable
 */
export function lookupSystemModuleState(id, module, index = 1, lookup) {
  return task({
    id,
    task_name: `${module}_${index}/${lookup}`,
    method: 'get',
    path: PATH
  });
}
/**
 * Get the list of functions for the given system module
 * @param id System ID
 * @param module Class name of the Module e.g. `Display`, `Lighting` etc.
 * @param index Module index. Defaults to `1`
 */
export function functionList(id, module, index = 1) {
  return task({
    id,
    task_name: `functions/${module}_${index}`,
    method: 'get',
    path: PATH
  });
}
/**
 * Occurances of a particular type of module in the given system
 * @param id System ID
 * @param module Class name of the Module e.g. `Display`, `Lighting` etc.
 */
export function moduleCount(id, module) {
  return task({
    id,
    task_name: 'count',
    form_data: {
      module
    },
    method: 'get',
    path: PATH
  });
}
/**
 * List types of modules and counts in the given system
 * @param id System ID
 */
export function moduleTypes(id) {
  return task({
    id,
    task_name: 'count',
    method: 'get',
    path: PATH
  });
}
/**
 * Get list of Zones for system
 * @param id System ID
 */
export function listSystemZones(id) {
  return query({
    query_params: {},
    fn: i => new PlaceZone(i),
    path: `${PATH}/${id}/zones`
  });
}
/**
 * Get list of triggers for system
 * @param id System ID
 */
export function listSystemTriggers(id) {
  return query({
    query_params: {},
    fn: i => new PlaceTrigger(i),
    path: `${PATH}/${id}/triggers`
  });
}
/**
 * Get list of triggers for system
 * @param id System ID
 * @param data Values for trigger properties
 */
export function addSystemTrigger(id, data) {
  return task({
    id,
    task_name: 'triggers',
    form_data: data,
    method: 'post',
    callback: item => new PlaceTrigger(item),
    path: PATH
  });
}
/**
 * Remove trigger from system
 * @param id System ID
 * @param trigger_id ID of the trigger
 */
export function removeSystemTrigger(id, trigger_id) {
  return task({
    id,
    task_name: `triggers/${trigger_id}`,
    method: 'del',
    path: PATH
  });
}
/**
 * Fetch settings of modules, zones and drivers associated with the system
 * @param id System ID
 */
export function systemSettings(id) {
  return task({
    id,
    task_name: 'settings',
    method: 'get',
    callback: list => list.map(item => new PlaceSettings(item)),
    path: PATH
  });
}
