import { PlaceResource } from '../resources/resource';
import { EncryptionLevel } from '../settings/interfaces';
import { PlaceSettings } from '../settings/settings';
import { PlaceTrigger } from '../triggers/trigger';
export class PlaceZone extends PlaceResource {
  constructor(raw_data = {}) {
    super(raw_data);
    /** Tuple of user settings of differring encryption levels for the zone */
    this.settings = [null, null, null, null];
    /**
     * List of modules associated with the system.
     * Only available from the show method with the `complete` query parameter
     */
    this.trigger_list = [];
    this.description = raw_data.description || '';
    this.tags = raw_data.tags || [];
    this.triggers = raw_data.triggers || [];
    this.settings = raw_data.settings || [null, null, null, null];
    this.parent_id = raw_data.parent_id || '';
    this.location = raw_data.location || '';
    this.display_name = raw_data.display_name || '';
    this.code = raw_data.code || '';
    this.type = raw_data.type || '';
    this.count = raw_data.count || 0;
    this.capacity = raw_data.capacity || 0;
    this.map_id = raw_data.map_id || '';
    this.timezone = raw_data.timezone || '';
    this.images = raw_data.images || [];
    this.playlists = raw_data.playlists || [];
    if (typeof this.settings !== 'object') {
      this.settings = [null, null, null, null];
    }
    for (const level in EncryptionLevel) {
      if (!isNaN(Number(level)) && !this.settings[level]) {
        this.settings[level] = new PlaceSettings({
          parent_id: this.id,
          encryption_level: +level
        });
      }
    }
    if (raw_data.trigger_data && raw_data.trigger_data instanceof Array) {
      this.trigger_list = raw_data.trigger_data.map(trigger => new PlaceTrigger(trigger));
    }
  }
}
