import { create, query, remove, show, task, update } from '../resources/functions';
import { PlaceDriver } from './driver';
/**
 * @private
 */
const PATH = 'drivers';
/** Convert raw server data to a driver object */
function process(item) {
  return new PlaceDriver(item);
}
/**
 * Query the available drivers
 * @param query_params Query parameters to add the to request URL
 */
export function queryDrivers(query_params = {}) {
  return query({
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Get the data for a driver
 * @param id ID of the driver to retrieve
 * @param query_params Query parameters to add the to request URL
 */
export function showDriver(id, query_params = {}) {
  return show({
    id,
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Update the driver in the database
 * @param id ID of the driver
 * @param form_data New values for the driver
 * @param query_params Query parameters to add the to request URL
 * @param method HTTP verb to use on request. Defaults to `patch`
 */
export function updateDriver(id, form_data, method = 'patch') {
  return update({
    id,
    form_data,
    query_params: {},
    method,
    fn: process,
    path: PATH
  });
}
/**
 * Add a new driver to the database
 * @param form_data Driver data
 * @param query_params Query parameters to add the to request URL
 */
export function addDriver(form_data) {
  return create({
    form_data,
    query_params: {},
    fn: process,
    path: PATH
  });
}
/**
 * Remove a driver from the database
 * @param id ID of the driver
 * @param query_params Query parameters to add the to request URL
 */
export function removeDriver(id, query_params = {}) {
  return remove({
    id,
    query_params,
    path: PATH
  });
}
/**
 * Request a recompilation of the driver's code
 * @param id ID of the driver
 */
export function recompileDriver(id) {
  return task({
    id,
    task_name: 'recompile',
    path: PATH
  });
}
/**
 * Request a reload of the driver's binary
 * @param id ID of the driver
 */
export function reloadDriver(id) {
  return task({
    id,
    task_name: 'reload',
    path: PATH
  });
}
/**
 * Query the compiled state of the driver's code
 * @param id ID of the driver
 */
export function isDriverCompiled(id) {
  return task({
    id,
    task_name: 'compiled',
    method: 'get',
    path: PATH
  });
}
