import { PlaceRepository } from './repository';
import { create, query, remove, show, task, update } from '../resources/functions';
/**
 * @private
 */
const PATH = 'repositories';
/** Convert raw server data to a repository object */
function process(item) {
  return new PlaceRepository(item);
}
/**
 * Query the available repositories
 * @param query_params Query parameters to add the to request URL
 */
export function queryRepositories(query_params = {}) {
  return query({
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Get the data for a repository
 * @param id ID of the repository to retrieve
 * @param query_params Query parameters to add the to request URL
 */
export function showRepository(id, query_params = {}) {
  return show({
    id,
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Update the repository in the database
 * @param id ID of the repository
 * @param form_data New values for the repository
 * @param query_params Query parameters to add the to request URL
 * @param method HTTP verb to use on request. Defaults to `patch`
 */
export function updateRepository(id, form_data, method = 'patch') {
  return update({
    id,
    form_data,
    query_params: {},
    method,
    fn: process,
    path: PATH
  });
}
/**
 * Add a new repository to the database
 * @param form_data Repository data
 * @param query_params Query parameters to add the to request URL
 */
export function addRepository(form_data) {
  return create({
    form_data,
    query_params: {},
    fn: process,
    path: PATH
  });
}
/**
 * Remove an repository from the database
 * @param id ID of the repository
 * @param query_params Query parameters to add the to request URL
 */
export function removeRepository(id, query_params = {}) {
  return remove({
    id,
    query_params,
    path: PATH
  });
}
/**
 * Get a list of all the interfaces
 * @param query_params Addition query parameters to pass to the request
 */
export function listInterfaceRepositories(query_params = {}) {
  return show({
    id: 'interfaces',
    query_params,
    path: PATH
  });
}
/**
 * Get name of the default branches for a new repository
 * @param query_params Details about the repository
 */
export function listRemoteRepositoryDefaultBranch(query_params) {
  return show({
    id: 'remote_default_branch',
    query_params,
    path: PATH
  });
}
/**
 * Get a list of branches for a new repository
 * @param query_params Details about the repository
 */
export function listRemoteRepositoryBranches(query_params) {
  return show({
    id: 'remote_branches',
    query_params,
    path: PATH
  });
}
/**
 * Get a list of branch commits for a new repository
 * @param query_params Details about the repository
 */
export function listRemoteRepositoryCommits(query_params) {
  return show({
    id: 'remote_commits',
    query_params,
    path: PATH
  });
}
/**
 * Get a list of tags for a new repository
 * @param query_params Details about the repository
 */
export function listRemoteRepositoryTags(query_params) {
  return show({
    id: 'remote_tags',
    query_params,
    path: PATH
  });
}
/**
 * Get a list of all the drivers for a repository
 * @param id ID of the repository
 * @param query Addition query parameters to pass to the request
 */
export function listRepositoryDrivers(id, query_params) {
  return task({
    id,
    task_name: 'drivers',
    form_data: query_params,
    method: 'get',
    path: PATH
  });
}
/**
 * Get a list of all the releases for a repository
 * @param id ID of the repository
 * @param query Addition query parameters to pass to the request
 */
export function listRepositoryReleases(id, query_params) {
  return task({
    id,
    task_name: 'releases',
    form_data: query_params,
    method: 'get',
    path: PATH
  });
}
/**
 * Get a list of all the commits for a repository
 * @param id ID of the repository
 * @param query Addition query parameters to pass to the request
 */
export function listRepositoryCommits(id, query_params) {
  return task({
    id,
    task_name: 'commits',
    form_data: query_params,
    method: 'get',
    path: PATH
  });
}
/**
 * Get a list of all the branches for a repository
 * @param id ID of the repository
 */
export function listRepositoryBranches(id) {
  return task({
    id,
    task_name: 'branches',
    method: 'get',
    path: PATH
  });
}
/**
 * Get a list of all the branches for a repository
 * @param id ID of the repository
 */
export function listRepositoryDefaultBranch(id) {
  return task({
    id,
    task_name: 'default_branch',
    method: 'get',
    path: PATH
  });
}
/**
 * Get a list of all the tags for a repository
 * @param id ID of the repository
 */
export function listRepositoryTags(id) {
  return task({
    id,
    task_name: 'tags',
    method: 'get',
    path: PATH
  });
}
/**
 * Get the details for a given driver
 * @param id ID of the repository
 * @param query Addition query parameters to pass to the request
 */
export function listRepositoryDriverDetails(id, query_params) {
  return task({
    id,
    task_name: 'details',
    form_data: query_params,
    method: 'get',
    path: PATH
  });
}
/**
 * Pull remote changes to tshe repository
 * @param id ID of the repository
 * @param query Addition query parameters to pass to the request
 */
export function pullRepositoryChanges(id, query_params) {
  return task({
    id,
    task_name: 'pull',
    form_data: query_params,
    method: 'post',
    path: PATH
  });
}
