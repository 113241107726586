import { create, query, remove, show, update } from '../resources/functions';
import { PlaceMQTTBroker } from './broker';
/**
 * @private
 */
const PATH = 'brokers';
/** Convert raw server data to a broker object */
function process(item) {
  return new PlaceMQTTBroker(item);
}
/**
 * Query the available MQTT brokers
 * @param query_params Query parameters to add the to request URL
 */
export function queryBrokers(query_params = {}) {
  return query({
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Get the data for a MQTT broker
 * @param id ID of the broker to retrieve
 * @param query_params Query parameters to add the to request URL
 */
export function showBroker(id, query_params = {}) {
  return show({
    id,
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Update the MQTT broker data
 * @param id ID of the broker
 * @param form_data New values for the broker
 * @param query_params Query parameters to add the to request URL
 * @param method HTTP verb to use on request. Defaults to `patch`
 */
export function updateBroker(id, form_data, method = 'patch') {
  return update({
    id,
    form_data,
    query_params: {},
    method,
    fn: process,
    path: PATH
  });
}
/**
 * Add a new MQTT broker to the database
 * @param form_data Broker data
 * @param query_params Query parameters to add the to request URL
 */
export function addBroker(form_data) {
  return create({
    form_data,
    query_params: {},
    fn: process,
    path: PATH
  });
}
/**
 * Remove a MQTT broker from the database
 * @param id ID of the broker
 * @param query_params Query parameters to add the to request URL
 */
export function removeBroker(id, query_params = {}) {
  return remove({
    id,
    query_params,
    path: PATH
  });
}
