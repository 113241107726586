import { PlaceResource } from '../resources/resource';
export class PlaceDomain extends PlaceResource {
  constructor(raw_data = {}) {
    super(raw_data);
    this.description = raw_data.description || '';
    this.domain = raw_data.domain || '';
    this.login_url = raw_data.login_url || '';
    this.logout_url = raw_data.logout_url || '';
    this.config = raw_data.config || {};
    this.internals = raw_data.internals || {};
    this.email_domains = raw_data.email_domains || [];
  }
}
