import { cleanObject } from '../utilities/general';
export class PlaceResource {
  constructor(raw_data = {}) {
    this.id = raw_data.id || '';
    this.name = raw_data.name || '';
    this.created_at = raw_data.created_at || 0;
    this.updated_at = raw_data.updated_at || 0;
    this.version = raw_data.version || 0;
  }
  /**
   * Convert object into plain object
   */
  toJSON() {
    const obj = Object.assign({}, this);
    obj.version = this.version;
    /** Remove unneeded public members */
    delete obj.created_at;
    return cleanObject(obj, [undefined, null, '']);
  }
}
