import { PlaceResource } from '../resources/resource';
export class PlaceOAuthSource extends PlaceResource {
  constructor(raw_data = {}) {
    super(raw_data);
    /** Type of auth source */
    this.type = 'oauth';
    this.authority_id = raw_data.authority_id || '';
    this.client_id = raw_data.client_id || '';
    this.client_secret = raw_data.client_secret || '';
    this.info_mappings = raw_data.info_mappings || {};
    this.authorize_params = raw_data.authorize_params || {};
    this.ensure_matching = raw_data.ensure_matching || {};
    this.site = raw_data.site || '';
    this.authorize_url = raw_data.authorize_url || 'oauth/authorize';
    this.token_method = raw_data.token_method || 'post';
    this.token_url = raw_data.token_url || 'oauth/token';
    this.auth_scheme = raw_data.auth_scheme || 'request_body';
    this.scope = raw_data.scope || '';
    this.raw_info_url = raw_data.raw_info_url || '';
    this.authorize_params = raw_data.authorize_params || {};
    this.ensure_matching = raw_data.ensure_matching || {};
  }
}
