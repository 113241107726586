import { map } from 'rxjs/operators';
import { apiEndpoint } from '../auth/functions';
import { del, get, patch, post, put, responseHeaders } from '../http/functions';
import { toQueryString } from '../utilities/api';
import { convertPairStringToMap, parseLinkHeader } from '../utilities/general';
/** Total number of items returned by the last basic index query */
export function requestTotal(name) {
  return _total[name] || 0;
}
/** Total number of items returned by the last basic index query */
export function lastRequestTotal(name) {
  return _last_total[name] || 0;
}
/** URL for the next query page */
export function next() {
  return _next;
}
/**
 * @private
 * Map of promises for Service
 */
const _observables = {};
/**
 * @private
 * Total number of items returned by the last basic index query
 */
let _total = {};
/**
 * @private
 * Total number of items returned by the last index query
 */
let _last_total = {};
/**
 * @private
 * URL to get the next page
 */
let _next = '';
const pass_fn = _ => _;
/**
 * @private
 */
export function cleanupAPI() {
  for (const key in _observables) {
    if (_observables[key]) {
      delete _observables[key];
    }
  }
  _total = {};
  _last_total = {};
  _next = '';
}
/**
 * @hidden
 * Query the index of the API route associated with this service
 * @param query_params Map of query paramaters to add to the request URL
 */
export function query(q) {
  const {
    query_params,
    fn,
    path,
    endpoint
  } = q;
  const query_str = toQueryString(query_params);
  const url = `${endpoint || apiEndpoint()}${path ? '/' + path : ''}${query_str ? '?' + query_str : ''}`;
  return get(url).pipe(map(resp => {
    const details = handleHeaders(url, query_str, path);
    return {
      total: details.total || 0,
      next: details.next ? () => query({
        query_params: details.next,
        fn,
        endpoint,
        path
      }) : null,
      data: resp && resp instanceof Array ? resp.map(i => (fn || pass_fn)(i)) : resp && !(resp instanceof Array) && resp.results ? resp.results.map(i => process(i)) : []
    };
  }));
}
/**
 * @hidden
 * Query the API route for a sepecific item
 * @param id ID of the item
 * @param query_params Map of query paramaters to add to the request URL
 */
export function show(details) {
  const {
    query_params,
    id,
    path,
    fn,
    options
  } = details;
  const query_str = toQueryString(query_params);
  const url = `${apiEndpoint()}/${path}/${id}${query_str ? '?' + query_str : ''}`;
  return get(url, options).pipe(map(resp => (fn || pass_fn)(resp)));
}
/**
 * @hidden
 * Make post request for a new item to the service
 * @param form_data Data to post to the server
 * @param query_params Map of query paramaters to add to the request URL
 */
export function create(details) {
  const {
    query_params,
    form_data,
    path,
    fn
  } = details;
  const query_str = toQueryString(query_params);
  const url = `${apiEndpoint()}/${path}${query_str ? '?' + query_str : ''}`;
  const observable = post(url, form_data).pipe(map(resp => (fn || pass_fn)(resp)));
  return observable;
}
/**
 * @hidden
 * Perform API task for the given item ID
 * @param id ID of the item
 * @param task_name Name of the task
 * @param form_data Map of data to pass to the API
 * @param method Verb to use for request
 */
export function task(details) {
  const {
    id,
    task_name,
    form_data,
    method,
    path,
    callback
  } = details;
  const query_str = toQueryString(form_data);
  const url = `${apiEndpoint()}/${path}/${id}/${task_name}`;
  const request = method === 'post' || method === 'put' || !method ? (method === 'put' ? put : post)(url, form_data) : (method === 'del' ? del : get)(`${url}${query_str ? '?' + query_str : ''}`, {
    response_type: 'json'
  });
  return request.pipe(map(resp => (callback || (_ => _))(resp)));
}
/**
 * @hidden
 * Make put request for changes to the item with the given id
 * @param id ID of the item being updated
 * @param form_data New values for the item
 * @param query_params Map of query paramaters to add to the request URL
 */
export function update(details) {
  const {
    id,
    query_params,
    form_data,
    method,
    path,
    fn
  } = details;
  const query_str = toQueryString(Object.assign(Object.assign({}, query_params), {
    version: form_data.version || 0
  }));
  const url = `${apiEndpoint()}/${path}/${id}${query_str ? '?' + query_str : ''}`;
  return (method === 'put' ? put : patch)(url, form_data).pipe(map(resp => (fn || pass_fn)(resp)));
}
/**
 * @hidden
 * Make delete request for the given item
 * @param id ID of item
 */
export function remove(details) {
  const {
    id,
    query_params,
    path
  } = details;
  const query_str = toQueryString(query_params);
  const url = `${apiEndpoint()}/${path}/${id}${query_str ? '?' + query_str : ''}`;
  return del(url);
}
/**
 * @private
 * @param url
 * @param query_str
 * @param name
 */
export function handleHeaders(url, query_str, name) {
  const headers = responseHeaders(url[0] === '/' ? `${location.origin}${url}` : url);
  const details = {
    total: 0,
    next: null
  };
  if (headers && headers['x-total-count']) {
    const total_value = +(headers['x-total-count'] || 0);
    if (query_str.length < 2 || query_str.length < 12 && query_str.indexOf('offset=') >= 0) {
      _total[name] = total_value;
    }
    _last_total[name] = total_value;
    details.total = total_value;
  }
  if (headers && headers.link) {
    const link_map = parseLinkHeader(headers.link || '');
    _next = link_map.next;
    details.next = convertPairStringToMap(_next.split('?')[1]);
  }
  return details;
}
function process(data) {
  return data;
}
