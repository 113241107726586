import { PlaceResource } from '../resources/resource';
import { EncryptionLevel } from '../settings/interfaces';
import { PlaceSettings } from '../settings/settings';
import { PlaceDriverRole } from './enums';
export class PlaceDriver extends PlaceResource {
  constructor(raw_data = {}) {
    var _a;
    super(raw_data);
    this.description = raw_data.description || '';
    this.module_name = raw_data.module_name || '';
    this.role = (_a = raw_data.role) !== null && _a !== void 0 ? _a : PlaceDriverRole.Logic;
    this.default_uri = raw_data.default_uri || '';
    this.default_port = raw_data.default_port || 1;
    this.ignore_connected = raw_data.ignore_connected || false;
    this.class_name = raw_data.class_name || '';
    this.repository_id = raw_data.repository_id || '';
    this.file_name = raw_data.file_name || '';
    this.commit = raw_data.commit || '';
    this.update_available = raw_data.update_available || false;
    this.update_info = raw_data.update_info;
    this.settings = raw_data.settings || [null, null, null, null];
    if (typeof this.settings !== 'object') {
      this.settings = [null, null, null, null];
    }
    for (const level in EncryptionLevel) {
      if (!isNaN(Number(level)) && !this.settings[level]) {
        this.settings[level] = new PlaceSettings({
          parent_id: this.id,
          encryption_level: +level
        });
      }
    }
  }
}
