import { PlaceResource } from '../resources/resource';
import { PlaceRepositoryType } from './interfaces';
export class PlaceRepository extends PlaceResource {
  /** Repository type */
  get type() {
    return this.repo_type;
  }
  constructor(raw_data = {}) {
    super(raw_data);
    this.folder_name = raw_data.folder_name || '';
    this.description = raw_data.description || '';
    this.uri = raw_data.uri || '';
    this.branch = raw_data.branch || '';
    this.commit_hash = raw_data.commit_hash || '';
    this.repo_type = raw_data.repo_type || PlaceRepositoryType.Driver;
    this.username = raw_data.username || '';
    this.password = raw_data.password || '';
  }
}
