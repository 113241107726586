import { create, query, remove, show, update } from '../resources/functions';
import { PlaceUser } from './user';
/**
 * @private
 */
const PATH = 'users';
/** Convert raw server data to a trigger object */
function process(item) {
  return new PlaceUser(item);
}
/**
 * Query the available triggers
 * @param query_params Query parameters to add the to request URL
 */
export function queryUsers(query_params = {}) {
  return query({
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Get the data for a trigger
 * @param id ID of the trigger to retrieve
 * @param query_params Query parameters to add the to request URL
 */
export function showUser(id, query_params = {}) {
  return show({
    id,
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Get the data for the currently logged in user
 * @param query_params Query parameters to add the to request URL
 */
export function currentUser(query_params = {}) {
  return show({
    id: 'current',
    query_params,
    fn: process,
    path: PATH
  });
}
/**
 * Update the trigger in the database
 * @param id ID of the trigger
 * @param form_data New values for the trigger
 * @param query_params Query parameters to add the to request URL
 * @param method HTTP verb to use on request. Defaults to `patch`
 */
export function updateUser(id, form_data, method = 'patch') {
  return update({
    id,
    form_data,
    query_params: {},
    method,
    fn: process,
    path: PATH
  });
}
/**
 * Add a new trigger to the database
 * @param form_data Trigger data
 * @param query_params Query parameters to add the to request URL
 */
export function addUser(form_data) {
  return create({
    form_data,
    query_params: {},
    fn: process,
    path: PATH
  });
}
/**
 * Remove an trigger from the database
 * @param id ID of the trigger
 * @param query_params Query parameters to add the to request URL
 */
export function removeUser(id, query_params = {}) {
  return remove({
    id,
    query_params,
    path: PATH
  });
}
