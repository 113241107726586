import { PlaceResource } from '../resources/resource';
export class PlaceApplication extends PlaceResource {
  constructor(raw_data = {}) {
    super(raw_data);
    this.uid = raw_data.uid || '';
    this.secret = raw_data.secret || '';
    this.owner_id = raw_data.owner_id || '';
    this.scopes = raw_data.scopes || '';
    this.redirect_uri = raw_data.redirect_uri || '';
    this.skip_authorization = raw_data.skip_authorization || false;
    this.preserve_client_id = raw_data.preserve_client_id || false;
  }
}
