import { PlaceResource } from '../resources/resource';
/**
 * Representation of the user model in Place
 */
export class PlaceUser extends PlaceResource {
  constructor(raw_data = {}) {
    super(raw_data);
    /** Password */
    this.password = '';
    /** Password */
    this.confirm_password = '';
    this.authority_id = raw_data.authority_id || '';
    this.email = raw_data.email || '';
    this.email_digest = raw_data.email_digest || '';
    this.phone = raw_data.phone || '';
    this.country = raw_data.country || '';
    this.building = raw_data.building || '';
    this.image = raw_data.image || '';
    this.metadata = raw_data.metadata || '';
    this.login_name = raw_data.login_name || '';
    this.staff_id = raw_data.staff_id || '';
    this.first_name = raw_data.first_name || '';
    this.last_name = raw_data.last_name || '';
    this.support = !!raw_data.support;
    this.sys_admin = !!raw_data.sys_admin;
    this.ui_theme = raw_data.ui_theme || '';
    this.card_number = raw_data.card_number || '';
    this.groups = raw_data.groups || [];
    this.department = raw_data.department || '';
    this.work_preferences = raw_data.work_preferences || [];
    this.work_overrides = raw_data.work_overrides || {};
  }
}
