export class PlaceMetadata {
  constructor(data = {}) {
    this.id = data.id || data.parent_id || '';
    this.name = data.name || '';
    this.description = data.description || '';
    try {
      this.details = (typeof data.details === 'string' ? JSON.parse(data.details) : data.details) || {};
    } catch (_a) {
      this.details = data.details || {};
    }
    this.editors = data.editors || [];
    this.schema = data.schema || '';
    this.updated_at = (data.updated_at || 0) * 1000 || Date.now();
    this.modified_by_id = data.modified_by_id || '';
    this.version = data.version || 0;
  }
}
