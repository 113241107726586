import { humanReadableByteCount } from '../utilities/general';
export class PlaceProcess {
  constructor(_cluster_id, raw_data = {}) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    this.cluster_id = _cluster_id;
    this.id = raw_data.id || raw_data.driver || '';
    this.modules = raw_data.modules || [];
    this.running = raw_data.running || false;
    this.module_instances = raw_data.module_instances || ((_b = (_a = raw_data.edge) === null || _a === void 0 ? void 0 : _a.status) === null || _b === void 0 ? void 0 : _b.module_instances) || ((_d = (_c = raw_data.local) === null || _c === void 0 ? void 0 : _c.status) === null || _d === void 0 ? void 0 : _d.module_instances) || 0;
    this.last_exit_code = raw_data.last_exit_code || ((_f = (_e = raw_data.edge) === null || _e === void 0 ? void 0 : _e.status) === null || _f === void 0 ? void 0 : _f.last_exit_code) || ((_h = (_g = raw_data.local) === null || _g === void 0 ? void 0 : _g.status) === null || _h === void 0 ? void 0 : _h.last_exit_code) || 0;
    this.launch_count = raw_data.launch_count || ((_k = (_j = raw_data.edge) === null || _j === void 0 ? void 0 : _j.status) === null || _k === void 0 ? void 0 : _k.launch_count) || ((_m = (_l = raw_data.local) === null || _l === void 0 ? void 0 : _l.status) === null || _m === void 0 ? void 0 : _m.launch_count) || 0;
    this.launch_time = raw_data.launch_time || ((_p = (_o = raw_data.edge) === null || _o === void 0 ? void 0 : _o.status) === null || _p === void 0 ? void 0 : _p.launch_time) || ((_r = (_q = raw_data.local) === null || _q === void 0 ? void 0 : _q.status) === null || _r === void 0 ? void 0 : _r.launch_time) || 0;
    this.cpu_usage = raw_data.cpu_usage || raw_data.percentage_cpu || ((_t = (_s = raw_data.edge) === null || _s === void 0 ? void 0 : _s.status) === null || _t === void 0 ? void 0 : _t.percentage_cpu) || ((_v = (_u = raw_data.local) === null || _u === void 0 ? void 0 : _u.status) === null || _v === void 0 ? void 0 : _v.percentage_cpu) || 0;
    this.memory_total = raw_data.memory_total || ((_x = (_w = raw_data.edge) === null || _w === void 0 ? void 0 : _w.status) === null || _x === void 0 ? void 0 : _x.memory_total) || ((_z = (_y = raw_data.local) === null || _y === void 0 ? void 0 : _y.status) === null || _z === void 0 ? void 0 : _z.memory_total) || 0;
    this.memory_usage = raw_data.memory_usage || ((_1 = (_0 = raw_data.edge) === null || _0 === void 0 ? void 0 : _0.status) === null || _1 === void 0 ? void 0 : _1.memory_usage) || ((_3 = (_2 = raw_data.local) === null || _2 === void 0 ? void 0 : _2.status) === null || _3 === void 0 ? void 0 : _3.memory_usage) || 0;
    this.used_memory = humanReadableByteCount(this.memory_usage * 1024);
    this.total_memory = humanReadableByteCount(this.memory_total * 1024);
  }
}
